import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Gtm = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageView',
      virtualPageUrl: pathname,
    })
  }, [pathname])

  return null
}

export default Gtm
