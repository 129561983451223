import PropTypes from 'prop-types'
import React from 'react'
import { StyledWrapper } from './styled'

const Content = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

Content.displayName = 'Content'

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
