import { useResponsive } from 'Hooks/useResponsive'
import { useTranslate } from 'Hooks/useTranslate'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LOCALES } from 'Utilities/enums/common'

const useComponent = (callback) => {
  const history = useHistory()
  const locale = useSelector((store) => store.config.locale)
  const locales = useSelector((store) => store.config.configs.locales)
  const [langCaption, setLangCaption] = useState('')
  const defaultLocale = useSelector(
    (store) => store.config.configs.defaultLocale,
  )
  const lang = locale === defaultLocale ? LOCALES.EN : LOCALES.FI
  const { isTabletOrMobile } = useResponsive()

  const handleSetLanguage = () => {
    callback && callback()
    history.push(`/${lang?.replace(defaultLocale, '')}`)
  }

  /*localization*/
  const { localized } = useTranslate({
    fi: 'Language.Finnish',
    en: 'Language.English',
  })

  useEffect(() => {
    setLangCaption(localized[lang])
  }, [localized, lang, setLangCaption])

  return {
    handleSetLanguage,
    langCaption,
    isTabletOrMobile,
    locales,
  }
}

export { useComponent }
