import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useComponent = () => {
  const pageContent = useSelector((store) => store.content.pageContent)
  const pageContentLoaded = useSelector(
    (store) => store.content.pageContentLoaded,
  )

  const pageConfigs = useSelector((store) => store.content.pageConfigs)
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    setImageUrl('')
  }, [location.pathname])

  useEffect(() => {
    if (pageContentLoaded) {
      setImageUrl(pageContent?.fields?.hero?.fields?.image?.fields.file?.url)
    }
  }, [
    pageContent?.fields?.hero?.fields?.image?.fields.file?.url,
    pageContentLoaded,
  ])

  return {
    hideHero: pageConfigs?.pageType && pageConfigs?.pageType == 'BlogPage',
    imageUrl:
      imageUrl && pageConfigs?.pageType && pageConfigs?.pageType !== 'BlogPage'
        ? imageUrl
        : '',
    patternImageUrl: '',
    pageContentLoaded,
    // pageContent?.fields?.hero?.fields?.pattern?.fields.file?.url,
    title: pageContent?.fields?.hero?.fields?.title,
    template: pageContent?.fields?.hero?.fields?.template,
    titleColor: pageContent?.fields?.hero?.fields?.titleColor,
    titleTextSize: pageContent?.fields?.hero?.fields?.titleTextSize,
    description: pageContent?.fields?.hero?.fields?.description,
    descriptionColor: pageContent?.fields?.hero?.fields?.descriptionColor,
    descriptionTextSize: pageContent?.fields?.hero?.fields?.descriptionTextSize,
    linkText: pageContent?.fields?.hero?.fields?.linkLabel,
    linkUrl: pageContent?.fields?.hero?.fields?.linkUrl,
    heightMobile: pageContent?.fields?.hero?.fields?.heightMobile,
    pageType: pageConfigs?.pageType,
    textAlignment:
      pageContent?.fields?.hero?.fields?.textAlignment?.toLowerCase(),
  }
}

export { useComponent }
