import TopNavigation from 'Components/Navigation/Top'
import React, { useEffect, useState } from 'react'
import Logo from 'Components/Logo'
import { StyledInnerWrapper, StyledItem, StyledWrapper } from './styled'
import { isRoot } from 'Utilities/utility'
import { useLocation } from 'react-router-dom'

const Header = () => {
  const location = useLocation()
  const [isRootPage, setIsRootPage] = useState(false)

  useEffect(() => {
    setIsRootPage(isRoot(location.pathname))
  }, [location.pathname])

  return (
    <StyledWrapper isRoot={isRootPage}>
      <StyledInnerWrapper>
        <StyledItem>
          <Logo />
        </StyledItem>
        <StyledItem>
          <TopNavigation />
        </StyledItem>
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

export default Header
