import React from 'react'
import FocusLock from 'react-focus-lock'
import { getCountrySpesificUrl } from 'Utilities/utility'
import LanguageSwitch from 'Components/LanguageSwitch'
import Burger from './Burger'
import Menu from './Burger/Drawer'
import { useComponent } from './extended'
import Link from './Link'
import { StyledWrapper } from './styled'

const TopNavigation = () => {
  const {
    navigationData,
    node,
    menuId,
    open,
    isTabletOrMobile,
    localized,
    locale,
    setOpen,
  } = useComponent()

  const links = navigationData?.map((item) => (
    <Link
      key={item.key}
      content={item}
      isMobile={isTabletOrMobile}
      callback={() => setOpen(false)}
    />
  ))

  return (
    <StyledWrapper>
      {isTabletOrMobile ? (
        <React.Fragment>
          <div ref={node}>
            <FocusLock disabled={!open}>
              <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
              <Menu open={open} setOpen={setOpen} id={menuId}>
                <Link
                  isMobile
                  isFrontPage
                  content={{
                    title: localized.frontPageTitle,
                    url: getCountrySpesificUrl(`/${locale || ''}`),
                  }}
                  callback={() => setOpen(false)}
                />
                {links}
                <LanguageSwitch callback={() => setOpen(false)} />
              </Menu>
            </FocusLock>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {links}
          <LanguageSwitch />
        </React.Fragment>
      )}
    </StyledWrapper>
  )
}

export default TopNavigation
