import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { StyledWrapper } from './styled'

const SvgInline = ({ url, height, marginLeft, width }) => {
  const [mounted, setMounted] = useState(null)
  const [svg, setSvg] = useState(null)

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    mounted &&
      fetch(url)
        .then((res) => mounted && res.text())
        .then(setSvg)
  }, [url, mounted])

  return (
    <StyledWrapper
      marginLeft={marginLeft}
      height={height}
      width={width}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

SvgInline.displayName = 'SvgInline'

SvgInline.propTypes = {
  marginLeft: PropTypes.string,
  height: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.string,
}

export default SvgInline
