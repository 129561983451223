import styled from 'styled-components'
import { SECTIONS_PAGE } from 'Utilities/enums/pageTypes'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.bgImage})}`};
  background-repeat: no-repeat;
  background-position: left bottom;
  // scroll-snap-align: ${(props) => (props.withScroll ? 'start' : '')};
  background: ${(props) =>
    props.pageType === SECTIONS_PAGE ? '' : 'rgba(0, 0, 0, 0.02)'};
  @media all and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    &&& {
      background-image: none;
      // scroll-snap-align: '';
    }
  }
`

const StyledTest = styled.div`
  width: 100%;
  height: 64px;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    background-color: red;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    background-color: green;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    background-color: yellow;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1160px) {
    background-color: brown;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    background-color: orange;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
    background-color: magenta;
  }
`

export { StyledWrapper, StyledTest }
