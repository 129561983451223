import arrow from 'Images/Arrow_btn.png'
import player from 'Images/Youtube_icon.png'
import { node } from 'prop-types'
import React from 'react'
import { StyledIcon, StyledLink, StyledText } from './styled'
import PropTypes from 'prop-types'

const Link = ({ children, type, ...rest }) => {
  const props = {}
  if (type === 'video') {
    props.rel = 'noopener noreferrer'
    props.target = '_blank'
  }
  return (
    <StyledLink {...rest} {...(type === 'video' ? { ...props } : {})}>
      {type === 'video' && (
        <StyledIcon
          src={type === 'video' ? player : arrow}
          type={type}
          alt={children}
        />
      )}

      <StyledText type={type}>{children}</StyledText>
    </StyledLink>
  )
}

Link.propTypes = {
  children: node.isRequired,
  type: PropTypes.string,
}

export default Link
