import { fetchPageType, fetchConfigs } from 'ApiCMS'
import { call, put, select } from 'redux-saga/effects'
import * as actions from 'Store/actions'
import { pageConfigs } from 'Utilities/configs/pageConfigs'
import { PAGE_NOT_FOUND } from 'Utilities/enums/pageTypes'
import { getCountrySpesificPagePath, getLocale } from 'Utilities/utility'
import { fetchLocalizedPageContentSaga } from './content'

export function* appInitSaga({ path }) {
  try {
    yield put(actions.setAppReady(false))
    const currentLocale = yield select((store) => store.config.locale)
    const configs = yield fetchConfigs(
      `Configs_${process.env.REACT_APP_ENVIRONMENT}`,
    )
    if (configs) {
      yield put(actions.setConfigs(configs))
    }
    let newLocale = getLocale(path, configs)
    const pageType = yield select((store) => store.content.pageType)

    if (currentLocale !== newLocale) {
      yield put(actions.setLocale(newLocale))
    }

    const localizedPath = getCountrySpesificPagePath(path, configs)
    yield call(fetchLocalizedPageContentSaga, { url: localizedPath })
    const pageContent = yield select((store) => store.content.pageContent)
    yield put(actions.setPageContent(pageContent))
    yield put(actions.fetchLocalizedLabels(path))
    const localeCode = yield select((store) => store.config.localeCode)
    let newPageType = yield fetchPageType(
      localeCode,
      'fields.pageType',
      localizedPath,
    )

    if (newPageType === '') {
      newPageType = PAGE_NOT_FOUND
    }
    if (pageType !== newPageType) {
      const pageConfig = pageConfigs.filter(
        (config) => config.pageType === newPageType,
      )[0]
      if (pageConfig) {
        yield put(actions.setPageConfigs(pageConfig))
      }
    }
  } catch (error) {
    //console.log(error)
  }
  yield put(actions.setAppReady(true))
}
