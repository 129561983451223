import { updateObject } from 'Utilities/utility'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  appReady: false,
  defaultLocale: '',
  locales: [],
  locale: 'fi',
  localeCode: 'fi-FI',
  path: '/',
  configs: {},
}

const setConfigs = (state, action) => {
  return updateObject(state, { configs: action.configs })
}

const setAppReady = (state, action) => {
  return updateObject(state, { appReady: action.appReady })
}

const setPath = (state, action) => {
  return updateObject(state, { path: action.path })
}

const setLocale = (state, action) => {
  const configs = state.configs
  let localeCode = ''
  localeCode = configs.locales?.filter((l) => l.locale === action.locale)[0]
    ?.localeCode
  localStorage.setItem('locale', action.locale)
  return updateObject(state, { locale: action.locale, localeCode: localeCode })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONFIGS:
      return setConfigs(state, action)
    case actionTypes.SET_APP_READY:
      return setAppReady(state, action)
    case actionTypes.SET_LOCALE:
      return setLocale(state, action)
    case actionTypes.SET_PATH:
      return setPath(state, action)
    default:
      return state
  }
}

export default reducer
