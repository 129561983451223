import PropTypes from 'prop-types'
import React from 'react'
import {
  StyledCardWrapper,
  StyledEmail,
  StyledImage,
  StyledName,
  StyledPhone,
  StyledSocial,
  StyledSocialIcon,
  StyledTitle,
} from '../styled'

const ContactCard = ({ content }) => {
  const { image, name, title, phone, email, icon, socialNetworks } = content
  const hadnleSocialClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <StyledCardWrapper>
      {icon && <StyledImage image={icon} alt={name} width="600" height="400" />}
      {image && (
        <StyledImage image={image} alt={name} width="600" height="400" />
      )}
      {name && <StyledName>{name}</StyledName>}
      {title && <StyledTitle>{title}</StyledTitle>}
      {phone && <StyledPhone>{phone}</StyledPhone>}
      {email && <StyledEmail>{email}</StyledEmail>}
      <StyledSocial>
        {socialNetworks?.fields?.socialNetworks?.map((sn) => (
          <StyledSocialIcon
            key={sn.fields?.title}
            src={sn.fields?.image?.fields?.file?.url}
            onClick={() => hadnleSocialClick(sn.fields?.url)}
            alt={sn.title}
          />
        ))}
      </StyledSocial>
    </StyledCardWrapper>
  )
}

ContactCard.displayName = 'ContactCard'

ContactCard.propTypes = {
  content: PropTypes.object,
}

export default ContactCard
