import styled from 'styled-components'

const StyledWrapper = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  //scroll-snap-align: start;

  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-bottom: 0;
  }
`

export { StyledWrapper }
