import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const StyledLinkWrapper = styled.div`
  margin-left: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xxl}) {
    margin-left: 24px;
  }
`

const StyledLinkWrapperMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: center;
`

const StyledLinkMobile = styled.a`
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  padding: 12px 0;
  font-weight: 400;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  transition: color 0.3s linear;
  font-size: 26px;
  text-align: center;
  z-index: 1;
`

const StyledLinkLine = styled.span`
  position: absolute;
  background: white;
  width: 100%;
  height: 20px;
  top: 36px;
`

const StyledLink = styled.a`
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: black;
  font-weight: 400;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 5px;
    bottom: -6px;
    left: 0px;
    background-color: #97ff29;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  ${(props) =>
    props.isActive &&
    `
    color: black;
    text-decoration-line: underline;
    text-underline-offset: 7px;
    text-decoration-thickness: 5px;
    text-decoration-color: #97ff29;
  `}

  text-transform: uppercase;
`

const StyledIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`

export const StyledBurger = styled.button`
  position: fixed;
  top: 18px;
  right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2.25rem;
  height: 2.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;
  &:focus {
    outline: 0;
  }
  span {
    width: 2.25rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.primaryBlack};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.green[1]};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  opacity: 0.9;
`

export const StyledMenuWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 50px;
  overflow: auto;
`

export {
  StyledWrapper,
  StyledLinkWrapper,
  StyledLinkWrapperMobile,
  StyledLink,
  StyledLinkMobile,
  StyledIcon,
  StyledLinkLine,
}
