import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import configReducer from './reducers/config'
import contentReducer from './reducers/content'
import { watchConfig, watchContent } from './sagas'

const composeEnhancers =
  (process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose

const rootReducer = combineReducers({
  config: configReducer,
  content: contentReducer,
})

// saga middleware
const sagaMiddleware = createSagaMiddleware()

const history = createBrowserHistory()
const middlewares = [sagaMiddleware, routerMiddleware(history)]

// apply middlewre
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
)

// run saga
sagaMiddleware.run(watchContent)
sagaMiddleware.run(watchConfig)

const getBrowserHistory = () => history
export { getBrowserHistory, store }
export default store
