import SvgInline from 'Components/SvgInline'
import PropTypes from 'prop-types'
import React from 'react'
import { useComponent } from './extended'
import { isRoot } from 'Utilities/utility'

import {
  StyledWrapper,
  StyledSparksWrapper,
  StyledSpark,
  StyledLogoWrapper,
  StyledLogoSquareWrapper,
} from './styled'
import { useLocation } from 'react-router'

const Logo = ({ height }) => {
  const { withLink, handleRedirect } = useComponent()
  const location = useLocation()

  const getSparks = () => {
    let sparks = []
    for (let i = 0; i < 6; i++) {
      sparks.push(
        <StyledSpark
          key={i}
          sparkIndex={isRoot(location.pathname) ? i + 1 : 0}
        ></StyledSpark>,
      )
    }
    return sparks
  }

  return (
    <StyledWrapper
      style={{ height: height, cursor: withLink ? 'pointer' : 'default' }}
      onClick={withLink ? handleRedirect : () => null}
    >
      <StyledLogoWrapper>
        <StyledLogoSquareWrapper withAnimation={isRoot(location.pathname)}>
          <SvgInline height="44px" width="44px" url={'/logo_square.svg'} />
        </StyledLogoSquareWrapper>
        <SvgInline height="44px" width="113px" url={'/logo_text.svg'} />
      </StyledLogoWrapper>
      <StyledSparksWrapper>
        {getSparks()}
        <div className="spark smk-2"></div>
      </StyledSparksWrapper>
    </StyledWrapper>
  )
}

Logo.displayName = 'Logo'

Logo.propTypes = {
  height: PropTypes.any,
}

export default Logo
