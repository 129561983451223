import Gtm from 'Components/GTM'
import AppConfig from 'Components/system/config/AppInit'
import Layout from 'Containers/layout/PageLayout'
import Routes from 'Containers/Router/Routes'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyling from './styles/globalStyling'
import theme from './styles/theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyling />
        <AppConfig />
        <Gtm />
        <Layout>
          <Routes />
        </Layout>
      </React.Fragment>
    </ThemeProvider>
  )
}

export default App
