import { useOnClickOutside } from 'Hooks/useOnClickOutside'
import { useResponsive } from 'Hooks/useResponsive'
import { useTranslate } from 'Hooks/useTranslate'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNavigationItems } from 'Store/actions'

const useComponent = () => {
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(null)
  const [navigationData, setNavigationData] = useState(null)
  const [open, setOpen] = useState(false)
  const locale = useSelector((store) => store.config.locale)
  const node = useRef()
  const menuId = 'main-menu'

  const { isTabletOrMobile } = useResponsive()

  /*localization*/
  const { localized } = useTranslate({
    frontPageTitle: 'Navigation.Home',
  })

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [setMounted])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  useOnClickOutside(node, () => setOpen(false))

  useEffect(() => {
    const callback = (data) => mounted && setNavigationData(data)
    mounted && dispatch(fetchNavigationItems('Top navigation', callback))
  }, [dispatch, mounted, locale])

  return {
    setOpen,
    navigationData,
    open,
    menuId,
    isTabletOrMobile,
    localized,
    locale,
  }
}

export { useComponent }
