import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { appInit } from 'Store/actions'

const useConfigs = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(appInit(location.pathname))
  }, [dispatch, location])
}

export { useConfigs }
