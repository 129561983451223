import React from 'react'
import {
  StyledCompaniesInnerWrapper,
  StyledCompaniesWrapper,
  StyledCompany,
  StyledTitleSmall,
} from '../styled'
import { useComponent } from './extended'

const Companies = () => {
  const { socialCompaniesData, handleClick } = useComponent()
  return (
    <>
      {socialCompaniesData && (
        <StyledCompaniesWrapper>
          <StyledTitleSmall isCentered>
            {socialCompaniesData.title}
          </StyledTitleSmall>
          <StyledCompaniesInnerWrapper>
            {socialCompaniesData?.items?.map((company) => (
              <StyledCompany
                isLink={Boolean(company.url)}
                key={company.key}
                src={company.image}
                onClick={() => handleClick(company.url)}
                alt={company.title}
              />
            ))}
          </StyledCompaniesInnerWrapper>
        </StyledCompaniesWrapper>
      )}
    </>
  )
}

Companies.displayName = 'Companies'

export default Companies
