import * as pageTypes from '../enums/pageTypes'

export const pageConfigs = [
  {
    pageType: pageTypes.SECTIONS_PAGE,
    component: 'SectionsPage',
  },
  {
    pageType: pageTypes.CONTENT_PAGE,
    component: 'ContentPage',
  },
  {
    pageType: pageTypes.BLOG_PAGE,
    component: 'BlogPage',
  },
  {
    pageType: pageTypes.PAGE_NOT_FOUND,
    component: 'PageNotFound',
  },
]
