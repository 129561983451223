import store from 'Store'

export const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const getLocale = (path, configs) => {
  if (configs?.locales?.length === 1) {
    return configs?.locales[0]?.locale
  }
  let locale = configs?.defaultLocale
  if (Array.isArray(configs?.locales)) {
    configs?.locales.forEach((val) => {
      if (path?.startsWith(`/${val.locale}/`) || path === `/${val.locale}`)
        locale = val.locale
    })
    return locale
  }
}

export const getCountrySpesificPagePath = (path) => {
  const state = store.getState()
  const configs = state.config.configs
  let locale = configs?.defaultLocale
  let isDefault = true
  if (Array.isArray(configs?.locales)) {
    configs?.locales.forEach((val) => {
      if (
        isDefault &&
        !(path?.startsWith(`/${locale}/`) || path === `/${locale}`) &&
        (path?.startsWith(`/${val.locale}/`) || path === `/${val.locale}`)
      ) {
        isDefault = false
      }
    })

    return isDefault ? `/${locale}${path}`.replace(/\/$/, '') : path
  }
}

export const getCountrySpesificUrl = (path) => {
  const state = store.getState()
  const configs = state.config.configs
  //check for external links
  if (path.startsWith('http') || path.startsWith('https')) return path
  let locale = configs.defaultLocale
  let isDefault = true
  if (Array.isArray(configs?.locales)) {
    configs?.locales.forEach((val) => {
      if (
        isDefault &&
        !(path?.startsWith(`/${locale}/`) || path === `/${locale}`) &&
        (path?.startsWith(`/${val.locale}/`) || path === `/${val.locale}`)
      ) {
        isDefault = false
      }
    })
    if (path === `/${locale}`) {
      if (isDefault) {
        return '/'
      } else {
        return `/${locale}`
      }
    }
    return isDefault
      ? `${path}`.replace(`/${locale}`, '').replace(/\/$/, '')
      : path
  }
}

export const translate = (translations, key) => {
  let result = undefined
  if (translations)
    result = translations[key] !== null ? translations[key] : key
  return result === undefined ? '' : result
}

export const isRoot = (path) => {
  return path === '/' || path === '/fi' || path === '/en'
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}
