import * as actionTypes from './actionTypes'

export const appInit = (path) => {
  return {
    type: actionTypes.APP_INIT,
    path: path,
  }
}

export const fetchConfigs = () => {
  return {
    type: actionTypes.FETCH_CONFIGS,
  }
}

export const setConfigs = (configs) => {
  return {
    type: actionTypes.SET_CONFIGS,
    configs: configs,
  }
}

export const setAppReady = (appReady) => {
  return {
    type: actionTypes.SET_APP_READY,
    appReady: appReady,
  }
}

export const setLocale = (locale) => {
  return {
    type: actionTypes.SET_LOCALE,
    locale: locale,
  }
}

export const setPath = (path) => {
  return {
    type: actionTypes.SET_PATH,
    path: path,
  }
}
