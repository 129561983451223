//app / config
export const APP_INIT = 'APP_INIT'
export const SET_APP_READY = 'SET_APP_READY'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_PATH = 'SET_PATH'
export const SET_CONFIGS = 'SET_CONFIGS'
//content
export const FETCH_CONFIGS = 'FETCH_CONFIGS'
export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS'
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS'
export const SET_TRANSLATIONS_LOADED = 'SET_TRANSLATIONS_LOADED'
export const FETCH_PAGE_CONTENT = 'FETCH_PAGE_CONTENT'
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT'
export const SET_PAGE_CONTENT_LOADED = 'SET_PAGE_CONTENT_LOADED'
export const SET_PAGE_READY = 'SET_PAGE_READY'
export const SET_PAGE_CONFIGS = 'SET_PAGE_CONFIGS'
export const FETCH_PAGE_SECTION_ITEMS = 'FETCH_PAGE_SECTION_ITEMS'
export const FETCH_CONTACT_CARDS_ITEMS = 'FETCH_CONTACT_CARDS_ITEMS'
export const FETCH_SOCIAL_NETWORKS_ITEMS = 'FETCH_SOCIAL_NETWORKS_ITEMS'
export const FETCH_NAVIGATION_ITEMS = 'FETCH_NAVIGATION_ITEMS'
export const FETCH_PAGES_BY_TYPE = 'FETCH_PAGES_BY_TYPE'
export const FETCH_COMPANY_ITEMS = 'FETCH_COMPANY_ITEMS'
export const FETCH_KEY_FIGURES_ITEMS = 'FETCH_KEY_FIGURES_ITEMS'
