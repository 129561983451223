import styled from 'styled-components'

const StyledWrapper = styled.div`
  width: 100%;
  // max-height: 1000px;
  //display: flex;
  svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    margin-left: ${(props) => props.marginLeft};
  }
`

export { StyledWrapper }
