import Link from 'Components/Link'
import React from 'react'
import Fade from 'react-reveal/Fade'
import { getCountrySpesificUrl } from 'Utilities/utility'
import { useComponent } from './extended'
import {
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledInnerWrapper,
  StyledTitle,
  StyledWrapper,
} from './styled'
import { useLocation } from 'react-router-dom'
import { isRoot } from 'Utilities/utility'
import ParallaxCamp from '../ParallaxCamp'

const HeroBanner = () => {
  const {
    imageUrl,
    patternImageUrl,
    title,
    titleColor,
    titleTextSize,
    description,
    descriptionColor,
    descriptionTextSize,
    linkText,
    linkUrl,
    height,
    heightMobile,
    textAlignment,
    template,
    pageContentLoaded,
    hideHero,
  } = useComponent()

  const location = useLocation()

  const hasContent = Boolean(title || description)

  if (hideHero) {
    return null
  }

  if (template === 'Parallax Camping' && pageContentLoaded) {
    return <ParallaxCamp />
  }

  return (
    <StyledContainer
      withScroll={isRoot(location.pathname)}
      height={isRoot(location.pathname) ? '100vh' : imageUrl ? '500px' : 0}
      withMargin={!isRoot(location.pathname)}
    >
      {imageUrl && (
        <Fade
          style={{
            height: isRoot(location.pathname)
              ? '100vh'
              : imageUrl
              ? '500px'
              : 0,
          }}
        >
          <StyledWrapper
            hasContent={hasContent}
            height={height}
            heightMobile={heightMobile}
            style={{
              backgroundImage: `url(${imageUrl ?? ''})`,
            }}
          >
            <StyledInnerWrapper
              hasContent={hasContent}
              height={height}
              heightMobile={heightMobile}
              style={{
                backgroundImage: `url(${patternImageUrl ?? ''})`,
              }}
            >
              {(title || description) && (
                <StyledContent
                  alignment={textAlignment}
                  heightMobile={heightMobile}
                >
                  {title && (
                    <StyledTitle
                      titleColor={titleColor}
                      titleTextSize={titleTextSize}
                      padding={textAlignment !== 'center' ? '64px' : ''}
                    >
                      {title}
                    </StyledTitle>
                  )}
                  {description && (
                    <StyledDescription
                      descriptionColor={descriptionColor}
                      descriptionTextSize={descriptionTextSize}
                    >
                      {description}
                    </StyledDescription>
                  )}
                  {linkUrl && linkText && (
                    <Link href={getCountrySpesificUrl(linkUrl ?? '')}>
                      {linkText}
                    </Link>
                  )}
                </StyledContent>
              )}
            </StyledInnerWrapper>
          </StyledWrapper>
        </Fade>
      )}
    </StyledContainer>
  )
}

export default HeroBanner
