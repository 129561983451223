import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchCompanyItems } from 'Store/actions'

const useComponent = () => {
  const [mounted, setMounted] = useState(null)
  const [socialCompaniesData, setSocialCompaniesData] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [setMounted])

  useEffect(() => {
    const callback = (data) => {
      mounted && setSocialCompaniesData(data)
    }
    mounted && dispatch(fetchCompanyItems('Partners', callback))
  }, [dispatch, mounted])

  const handleClick = (url) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  return { socialCompaniesData, handleClick }
}

export { useComponent }
