import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

const useComponent = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const locale = useSelector((store) => store.config.locale)
  const locales = useSelector((store) => store.config.configs.locales)

  const defaultLocale = useSelector(
    (store) => store.config.configs.defaultLocale,
  )

  const [withLink, setWithLink] = useState(true)

  useEffect(() => {
    setWithLink(true)
    locales?.forEach((val) => {
      const l = val.locale.replace(defaultLocale, '')
      if (pathname === '/' + l) {
        setWithLink(false)
      }
    })
  }, [locale, pathname, defaultLocale, locales, setWithLink])

  const handleRedirect = () => {
    history.push('/' + locale?.replace(defaultLocale, ''))
  }

  return {
    withLink,
    handleRedirect,
  }
}

export { useComponent }
