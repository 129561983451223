import ContactCards from 'Components/ContactCards'
import React from 'react'
import {
  StyledContactCardsWrapper,
  StyledReveal,
  StyledSectionWrapper,
  StyledSocialInnerWrapper,
  StyledSocialWrapper,
} from '../styled'
import PropTypes from 'prop-types'

const Social = ({ withNavigation }) => {
  return (
    <StyledSectionWrapper>
      <StyledReveal>
        <StyledSocialWrapper>
          <StyledSocialInnerWrapper>
            <StyledContactCardsWrapper>
              <ContactCards withNavigation={withNavigation} />
            </StyledContactCardsWrapper>
          </StyledSocialInnerWrapper>
        </StyledSocialWrapper>
      </StyledReveal>
    </StyledSectionWrapper>
  )
}

Social.displayName = 'Social'

Social.propTypes = {
  withNavigation: PropTypes.any,
}

export default Social
