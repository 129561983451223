import styled from 'styled-components'

const StyledLinkWrapper = styled.div`
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`

const StyledLink = styled.a`
  margin-left: 48px;
  cursor: pointer;
  padding: 7px 0;
  text-transform: uppercase;
  font-weight: 400;
  ${(props) =>
    props.isTabletOrMobile &&
    `
      margin-left: 0;
      letter-spacing: 0.5rem;
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
      transition: color 0.3s linear;
      font-size: 1.5rem;
      text-align: center;
      text-transform: none;
  `}
`

export { StyledLinkWrapper, StyledLink }
