import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  padding: 0 25px;

  a {
    font-size: ${(props) => props.theme.fontSize.body.l};
    color: ${(props) => props.theme.colors.primaryBlack};
    text-decoration: none;
    @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
      margin: 8px;
    }
  }

  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: 24px;
  }

  & > div {
    @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
      & a {
        text-align: center;
      }
    }
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 100%;
    align-items: center;
  }
`

const StyledImage = styled.div`
  background-image: ${(props) => `url(${props.image})}`};
  align-items: center;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 180px;
  height: 180px;
  margin-bottom: 12px;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 100%;
    max-height: 100%;
    width: 180px;
    height: 180px;
  }
`

const StyledTitle = styled.div``

const StyledName = styled.div`
  font-size: ${(props) => props.theme.fontSize.body.m};
  font-weight: 600;
  margin-bottom: 12px;
  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: ${(props) => props.theme.spacing.xs2};
  }
`

const StyledPhone = styled.div``

const StyledEmail = styled.div``

const StyledSocialIcon = styled.img`
  padding: 16px 8px 16px 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
`

const StyledSocial = styled.div`
  display: flex;
  justify-content: flex-start;
`
const StyledNavigation = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    &&& {
      margin-top: ${(props) => props.theme.spacing.s};
    }
  }
`

const StyledNavigationItem = styled.a`
  margin-bottom: ${(props) => props.theme.spacing.xs2};
  color: ${(props) => props.theme.colors.green[0]};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    // color: ${(props) => props.theme.colors.green[0]};
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-top: ${(props) => props.theme.spacing.xs2};
  }
`

export {
  StyledWrapper,
  StyledCardWrapper,
  StyledImage,
  StyledTitle,
  StyledName,
  StyledPhone,
  StyledEmail,
  StyledSocialIcon,
  StyledSocial,
  StyledNavigation,
  StyledNavigationItem,
}
