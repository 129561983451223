import styled from 'styled-components'

const StyledContainer = styled.div`
  height: ${(props) =>
    props.height === '100vh' ? 'calc(100vh - 88px)' : props.height};
  min-height: ${(props) =>
    props.height === '100vh' ? 'calc(100vh - 88px)' : props.height};
  margin-top: ${(props) => (props.withMargin ? '88px' : '')};

  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    height: ${(props) => props.height};
    min-height: ${(props) => props.height};
    margin-top: ${(props) => (props.withMargin ? '73px' : '')};
  }

  position: relative;
  width: 100%;
  display: flex;

  flex-direction: column;
  & > div {
    height: ${(props) => (props.height === '100vh' ? '100vh' : props.height)};
  }

  ${(props) =>
    props.withScroll &&
    `
    // scroll-snap-align: start;
  `}
`

const StyledWrapper = styled.div`
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
  }
`

const StyledInnerWrapper = styled.div`
  width: 100%;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    height: ${(props) =>
      props.heightMobile ? props.heightMobile : props.theme.breakpoints.s};
  }

  align-items: ${(props) =>
    props.alignment === 'right'
      ? 'flex-end'
      : props.alignment === 'left'
      ? 'flex-start'
      : 'center'};
`

const StyledTitle = styled.h1`
  font-size: ${(props) =>
    props.titleTextSize
      ? props.titleTextSize
      : props.theme.fontSize.heading.h1};
  margin: ${(props) => props.theme.spacing.xs2};
  padding: ${(props) => props.padding};
  color: ${(props) =>
    props.titleColor ? props.titleColor : props.theme.colors.primaryBlack};
  font-weight: 300;
  letter-spacing: -0.03em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);

  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${(props) => props.theme.fontSize.headingMobile.h1};
    padding: 24px;
  }

  @media all and (min-width: ${(props) =>
      props.theme.breakpoints.m}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fontSize.headingTablet.h1};
    margin-bottom: ${(props) => props.theme.spacing.xs2};
  }
`

const StyledDescription = styled.p`
  font-size: ${(props) =>
    props.descriptionTextSize
      ? props.descriptionTextSize
      : props.theme.fontSize.heading.xs};
  color: ${(props) =>
    props.descriptionColor
      ? props.descriptionColor
      : props.theme.colors.primaryBlack};
  text-transform: uppercase;
  margin-bottom: ${(props) => props.theme.spacing.l};
  letter-spacing: 0.1em;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    text-align: center;
    margin: ${(props) => props.theme.spacing.xs};
  }
`

export {
  StyledWrapper,
  StyledContainer,
  StyledInnerWrapper,
  StyledContent,
  StyledTitle,
  StyledDescription,
}
