import styled from 'styled-components'

const StyledLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSize.heading.xs};
`

const StyledText = styled.span`
  display: inline-block;
  font-size: ${(props) =>
    props.type === 'video' ? '' : props.theme.fontSize.body.p};
`

const StyledIcon = styled.img`
  height: ${(props) => (props.type === 'video' ? '64px' : '16px')};
  display: flex;
  margin-right: ${(props) => props.theme.spacingLayout.xs2};
  padding-top: ${(props) => (props.type === 'video' ? '10px' : '0')};
`

export { StyledLink, StyledText, StyledIcon }
