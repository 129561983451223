const theme = {
  breakpoints: {
    xs: '320px',
    s: '576px',
    m: '720px',
    l: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  colors: {
    c25: {
      primary: 'rgba(255, 192, 64, 0.25)',
      primaryBlack: 'rgba(51, 51, 51, 0.25)',
      primaryGray: 'rgba(213, 217, 219, 0.25)',
      black: 'rgba(0, 0, 0, 0.25)',
      white: 'rgba(255, 255, 255, 0.25)',
      blue: 'rgba(19, 171, 255, 0.25)',
      sapphire: 'rgba(70, 56, 199, 0.25)',
      green: 'rgba(14, 192, 122, 0.25)',
      red: 'rgba(235, 78, 65, 0.25)',
      orange: 'rgba(255, 128, 32, 0.25)',
    },
    c50: {
      primary: 'rgba(255, 192, 64, 0.5)',
      primaryBlack: 'rgba(51, 51, 51, 0.5)',
      primaryGray: 'rgba(213, 217, 219, 0.5)',
      black: 'rgba(0, 0, 0, 0.5)',
      white: 'rgba(255, 255, 255, 0.5)',
      blue: 'rgba(19, 171, 255, 0.5)',
      sapphire: 'rgba(70, 56, 199, 0.5)',
      green: 'rgba(14, 192, 122, 0.5)',
      red: 'rgba(235, 78, 65, 0.5)',
      orange: 'rgba(255, 128, 32, 0.5)',
    },
    c75: {
      primary: 'rgba(255, 192, 64, 0.75)',
      primaryBlack: 'rgba(51, 51, 51, 0.75)',
      primaryGray: 'rgba(213, 217, 219, 0.75)',
      black: 'rgba(0, 0, 0, 0.75)',
      white: 'rgba(255, 255, 255, 0.75)',
      blue: 'rgba(19, 171, 255, 0.75)',
      sapphire: 'rgba(70, 56, 199, 0.75)',
      green: 'rgba(14, 192, 122, 0.75)',
      red: 'rgba(235, 78, 65, 0.75)',
      orange: 'rgba(255, 128, 32, 0.75)',
    },
    primary: '#2b2b2b',
    primaryBlack: '#2b2b2b',
    primaryGray: '#4f4e4a',
    black: '#2b2b2b',
    white: '#fff',
    blue: '#1890ff',
    sapphire: '#4C5EC3',
    green: ['#96FF29', '#C6FF2C', '#CCFF2C'],
    red: '#EB4E41',
    orange: '#FF8020',
    transparent: 'transparent',
    text: '#2b2b2b',
    grays: ['#f9f9f9', '#474747'],
  },
  fontSize: {
    heading: {
      xxl: '7.25rem',
      xl: '3.25rem',
      l: '2.25rem',
      m: '1.5rem',
      s: '1.25rem',
      xs: '1.125rem',
      xxs: '1rem',
      h1: '7.25rem', // 116px ?
      h2: '3.3rem', //58px
      h3: '2.2rem', //42px
      h4: '1.625rem', //26px
    },
    headingTablet: {
      h1: '4.25rem', //68px
      h2: '2.625rem', //42px
      h3: '1.625rem', //26px
    },
    headingMobile: {
      h1: '3.5rem', //??
      h2: '2rem', //42px
      h3: '1.625rem', //26px
    },
    body: {
      s: '0.875rem',
      m: '1rem',
      l: '1.125rem',
      xl: '1.25rem',
      pxl: '2.2rem', //42px
      p: '1.625rem', // 26px
      pTablet: '1.25rem', // ??px
      //blockquote
      bq: '2rem', // 26px
    },
    bodyMobile: {
      s: '0.875rem',
      m: '1rem',
      l: '1.125rem',
      xl: '1.25rem',
      pxl: '1.625rem', //26px ??
      p: '1rem', // 16px
      //blockquote
      bq: '1rem', // 26px
    },
  },
  containerWidth: {
    xs: '288px',
    s: '544px',
    m: '720px',
    l: '944px',
    xl: '1200px',
  },
  spacing: {
    xs2: '1rem',
    xs: '1.5rem',
    s: '2rem',
    m: '3rem',
    l: '4rem',
    xl: '6rem',
    xl2: '8rem',
  },
  spacingLayout: {
    xs4: '0.125rem',
    xs3: '0.25rem',
    xs2: '0.5rem',
    xs: '0.75rem',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
    xl: '2.5rem',
    xl2: '3.0rem',
    xl3: '3.5rem',
    xl4: '4rem',
    xl5: '4.5rem',
  },
  lineHeight: {
    s: 1,
    m: 1.2,
    l: 1.5,
    xl: 1.75,
  },
}

export default theme
