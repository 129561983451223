import { format } from 'date-fns'
import {
  fetchContactCardsListEntries,
  fetchLabelEntries,
  fetchNavigationEntries,
  fetchPageEntries,
  fetchPageSectionListEntries,
  fetchPageWithFields,
  fetchSocialNetworksListEntries,
  fetchPageEntriesByContentTypeWithLimit,
  fetchCompanyListEntries,
  fetchConfigsEntries,
} from './contentfulClient'

const fetchLabels = (locale) =>
  fetchLabelEntries(locale).then((entries) =>
    entries.items?.reduce(
      (acc, item) =>
        Object.assign(acc, {
          [item.fields?.key]: item.fields?.value
            ? item.fields?.value
            : item.fields?.key,
        }),
      {},
    ),
  )

const fetchConfigs = (title) =>
  fetchConfigsEntries(title).then((entries) => {
    const configs = entries.items[0]
    try {
      //not draft
      if (configs?.fields) {
        return { ...configs.fields?.configs }
      }
    } catch (error) {
      return {}
    }
  })

const fetchPageType = (locale, select, url) =>
  fetchPageWithFields(locale, select, url).then((entries) => {
    const page = entries.items[0]
    try {
      //not draft
      if (page?.fields) {
        return page.fields?.pageType
      }
    } catch (error) {
      return ''
    }
  })

const fetchPageItems = (locale, url) =>
  fetchPageEntries(locale, url).then((entries) => entries.items)

const fetchPageSectionItems = (locale, title) =>
  fetchPageSectionListEntries(locale, title).then((entries) => {
    const sectionList = entries.items[0]
    let result = null
    try {
      //not draft
      if (sectionList?.fields?.sections) {
        result = sectionList?.fields?.sections
          ?.filter((sectionItem) => sectionItem?.fields !== undefined)
          .map((item) => ({
            key: item.sys?.id,
            title: item.fields?.title ?? '',
            text: item.fields?.richText,
            layout: item.fields?.layout ?? '',
            videoId: item.fields?.videoId ?? '',
            image: item.fields?.image?.fields.file.url ?? '',
            image2: item.fields?.image2?.fields.file.url ?? '',
            list: item.fields?.list ?? [],
            dynamicList: item.fields?.dynamicList ?? '',
            itemsNumber: item.fields?.itemsNumber ?? 3,
            pattern: item.fields?.pattern?.fields.file.url ?? '',
            patternLocation: item.fields?.patternLocation ?? '',
            backgroundColor: item.fields?.backgroundColor ?? '',
            backgroundImage: item.fields?.background?.fields.file.url ?? '',
            backgroundLocation: item.fields?.backgroundLocation ?? '',
          }))
      }
    } catch (error) {
      //console.log(error)
    }
    return result
  })

const fetchPageItemsByType = (pageType, locale, limit, skip, tags) =>
  fetchPageEntriesByContentTypeWithLimit(
    pageType,
    locale,
    limit,
    skip,
    tags,
  ).then((entries) => {
    //not reusable, mainly for different page types
    const list = entries.items
    let result = null
    try {
      //not draft
      if (list) {
        result = list
          ?.filter((page) => page?.fields !== undefined)
          .map((item) => ({
            fields: {
              key: item.sys?.id,
              title: item.fields?.title ?? '',
              description: item.fields?.description ?? '',
              ingress: item.fields?.ingress ?? '',
              descriptionImage:
                item.fields?.descriptionImage?.fields?.file?.url ?? '',
              pageType: item.fields?.pageType ?? '',
              url: item.fields?.url ?? '',
              content: item.fields?.content ?? {},
              author: item.fields?.author
                ? {
                    name: item.fields?.author?.fields?.Name,
                    photoUrl:
                      item.fields?.author?.fields?.photo?.fields?.file?.url,
                  }
                : null,
              publishingDate: format(
                new Date(item?.fields?.publishingDate),
                'dd.MM.yyyy',
              ),
              tags: item?.metadata.tags,
            },
          }))
      }
    } catch (error) {
      //console.log(error)
    }
    return { result: result, total: entries.total }
  })

const fetchContactCardItems = (locale, title) =>
  fetchContactCardsListEntries(locale, title).then((entries) => {
    const cardsList = entries.items[0]
    let result = null
    try {
      //not draft
      if (cardsList.fields) {
        result = cardsList?.fields?.contactCardsList
          .filter((card) => card.fields !== undefined)
          .map((item) => ({
            key: item.sys?.id,
            name: item.fields?.Name ?? '',
            title: item.fields?.title ?? '',
            email: item.fields?.email?.replace('@', '(a)') ?? '',
            phone: item.fields?.phone ?? '',
            socialNetworks: item.fields?.socialNetworks ?? [],
            image: item.fields?.photo?.fields?.file?.url ?? '',
          }))
      }
    } catch (error) {
      //console.log(error)
    }
    return result
  })

const fetchSocialNetworkItems = (locale, title) =>
  fetchSocialNetworksListEntries(locale, title).then((entries) => {
    const networksList = entries.items[0]
    let result = null
    try {
      //not draft
      if (networksList.fields) {
        result = networksList.fields.socialNetworks
          .filter((network) => network.fields !== undefined)
          .map((item) => ({
            key: item.sys?.id,
            title: item.fields?.title ?? '',
            url: item.fields?.url ?? '',
            image: item.fields.image?.fields?.file?.url ?? '',
          }))
      }
    } catch (error) {
      //console.log(error)
    }
    return result
  })

const fetchNavigationItems = (locale, title) =>
  fetchNavigationEntries(locale, title).then((entries) => {
    const links = entries.items[0]
    let result = null
    try {
      //not draft
      if (links.fields) {
        result = links.fields.links
          .filter((link) => link.fields !== undefined)
          .map((item) => ({
            key: item.sys?.id,
            title: item.fields?.title ?? '',
            url: item.fields?.url ?? '',
          }))
      }
    } catch (error) {
      //console.log(error)
    }
    return result
  })

const fetchCompanyItems = (locale, title) =>
  fetchCompanyListEntries(locale, title).then((entries) => {
    const companyList = entries.items[0]
    let result = null
    try {
      //not draft
      if (companyList.fields) {
        result = companyList.fields.companies
          .filter((network) => network.fields !== undefined)
          .map((item) => ({
            key: item.sys?.id,
            title: item.fields?.title ?? '',
            image: item.fields.logo?.fields?.file?.url ?? '',
            url: item.fields?.url ?? '',
          }))
      }
    } catch (error) {
      //console.log(error)
    }
    return { title: companyList.fields.header, items: result }
  })

export {
  fetchLabels,
  fetchPageItems,
  fetchPageSectionItems,
  fetchContactCardItems,
  fetchSocialNetworkItems,
  fetchNavigationItems,
  fetchPageType,
  fetchPageItemsByType,
  fetchCompanyItems,
  fetchConfigs,
}
export default null
