import PropTypes from 'prop-types'
import React from 'react'
import Content from './Content'
import Footer from './Footer'
import Header from './Header'
import HeroBanner from 'Components/HeroBanner'
import { StyledLayout } from './styled'
import { useLocation } from 'react-router'
import { isRoot } from 'Utilities/utility'

const Layout = ({ children }) => {
  const location = useLocation()
  return (
    <StyledLayout withScroll={isRoot(location.pathname)}>
      <HeroBanner />
      <Header />
      <Content>{children}</Content>
      <Footer withScroll={isRoot(location.pathname)} />
    </StyledLayout>
  )
}

Layout.displayName = 'Layout'

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
