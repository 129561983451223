import React, { useEffect, useState } from 'react'
import { StyledWrapper } from './styled'
import Social from 'Components/PageSection/Social'
import Partners from 'Components/PageSection/Companies'
import { useSelector } from 'react-redux'
import pattern from 'Images/Pattern_footer.png'
import { useLocation } from 'react-router-dom'
import { isRoot } from '../../../../utilities/utility'
import Fade from 'react-reveal/Fade'

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false)
  const location = useLocation()
  const pageContentLoaded = useSelector(
    (store) => store.content.pageContentLoaded,
  )
  const pageContent = useSelector((store) => store.content.pageContent)
  const pageConfigs = useSelector((store) => store.content.pageConfigs)

  useEffect(() => {
    if (pageContent) {
      setShowFooter(true)
    }
  }, [pageContent])

  useEffect(() => {
    setShowFooter(false)
  }, [location])

  return (
    <>
      {pageContentLoaded && pageContent && showFooter && (
        <Fade delay={500}>
          <StyledWrapper
            withScroll={isRoot(location.pathname)}
            pageType={pageConfigs?.pageType}
            bgImage={pattern}
          >
            <Social withNavigation={true} />
            <Partners />
          </StyledWrapper>
        </Fade>
      )}
    </>
  )
}

export default Footer
