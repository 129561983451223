import { BLOG_PAGE } from './pageTypes'

export const LOCALES = {
  EN: 'en',
  FI: 'fi',
}

export const PAGE_SECTIONS = {
  CARDS_NO_BORDER: 'CardsNoBorder',
  CARDS_WITH_BORDER: 'CardsBordered',
  IMAGE_LEFT: 'ImageOnTheLeft',
  CENTER: 'CenteredColumn',
  IMAGE_RIGHT: 'ImageOnTheRight',
  SOCIAL: 'Social',
  VIDEO: 'Video',
  INFINITE: 'Infinite',
  CONTACT_FORM: 'ContactForm',
  KEY_FIGURES: 'KeyFigures',
  KEY_STONES: 'KeyStones',
  REFERENCES_LIST: 'ReferencesList',
  CUSTOMERS_GRID: 'CustomersGrid',
}

export const PAGE_LISTS = {
  BLOGS: 'Blogs',
  NEWS: 'News',
}

export const PAGE_TYPES_FROM_LIST = {
  [PAGE_LISTS.BLOGS]: BLOG_PAGE,
}
