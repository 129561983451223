import styled from 'styled-components'

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media all and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    ${(props) =>
      props.withScroll &&
      `
    overflow-y: auto;
    height: 100vh;
    // scroll-snap-type: y mandatory;
  `}
  }
`

export { StyledLayout }
