import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchContactCardItems, fetchNavigationItems } from 'Store/actions'
import { useTranslate } from 'Hooks/useTranslate'

const useComponent = () => {
  const [mounted, setMounted] = useState(null)
  const [contactCardsData, setContactCardsData] = useState(null)
  const [navigationData, setNavigationData] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [setMounted])

  /*localization*/
  const { localized } = useTranslate({
    frontPageTitle: 'Navigation.Home',
  })

  useEffect(() => {
    const callback = (data) => mounted && setContactCardsData(data)

    const callbackNavigation = (data) => mounted && setNavigationData(data)
    if (mounted) {
      dispatch(fetchContactCardItems(callback))
      dispatch(fetchNavigationItems('Bottom navigation', callbackNavigation))
    }
  }, [dispatch, mounted])

  return { contactCardsData, navigationData, localized }
}

export { useComponent }
