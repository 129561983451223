import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
`

const StyledLogoSquareWrapper = styled.div`
  overflow: visible;
  transform-origin: center;
  transform-box: fill-box;
  animation: beat 1s ease-out 2;
  animation-delay: 1s;
  transform: scale(1);

  ${(props) =>
    props.withAnimation &&
    `

  @keyframes beat {
    0% {
      transform: scale(1);
    }
    15% {
      transform: scale(0.8);
    }
    30% {
      transform: scale(1);
    }
    45% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }  
`}
`

const StyledLogoWrapper = styled.div`
  display: flex;
`

const StyledSparksWrapper = styled.div`
  position: absolute;
  left: 5%; /*-45vw*/
  top: -3vh; /*15%*/
  margin-left: 0;
  z-index: 1000000;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    display: none;
  }
`

const StyledSpark = styled.div`
  color: green;
  position: relative;
  top: 0;
  background-color: currentColor;
  position: relative;
  border-color: currentColor;
  width: 0.5rem;
  height: 0.5rem;
  animation-timing-function: ease-out;
  animation-iteration-count: 2;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  transform: scale(0);

  ${(props) =>
    props.sparkIndex === 1 &&
    `
      color: #97FF29;
      animation-duration: 2.7s;
      animation-name: burn-smoke-alt;
      left:2rem;
  `}

  ${(props) =>
    props.sparkIndex === 2 &&
    `
      color: #FFFF15;
      animation-duration: 1.2s; 
      animation-name: burn-smoke-alt;
      left: -1.5rem;
  `}

${(props) =>
    props.sparkIndex === 3 &&
    `
      color: #CCFF2C;
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
      animation-duration: 2.6s;
      animation-name: burn-smoke-alt;
      left: 1rem;
  `}

${(props) =>
    props.sparkIndex === 4 &&
    `
      color: #64F780;
      animation-duration: 1.1s; 
      animation-name: burn-smoke;
      left: 1.5rem;: burn-smoke-alt;
      left:2rem;
  `}

${(props) =>
    props.sparkIndex === 5 &&
    `
      color: #00AA95;
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
      animation-duration: 1.9s; 
      animation-name: burn-smoke;
      left: 2rem;
  `}

${(props) =>
    props.sparkIndex === 6 &&
    `
      color: #97FF29;
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
      animation-duration: 1.5s; 
      animation-name: burn-smoke;
      left: -0.5rem;
  `}

  @keyframes burn-smoke {
    0% {
      transform: rotate(0deg) scale(3);
      opacity: 1;
      left: 0px;
    }

    38% {
      opacity: 1;
      left: 18vw;
    }

    100% {
      transform: rotate(360deg) scale(0);
      opacity: 0;
      left: 52vw;
      top: -60vh;
    }
  }

  @keyframes burn-smoke-alt {
    0% {
      transform: rotate(0deg) scale(3);
      opacity: 1;
      left: 0px;
    }

    35% {
      opacity: 1;
      left: 10vw;
    }

    100% {
      transform: rotate(-360deg) scale(0);
      opacity: 0;
      left: 20vw;
      top: -60vh;
    }
  }
`

export {
  StyledWrapper,
  StyledSparksWrapper,
  StyledSpark,
  StyledLogoWrapper,
  StyledLogoSquareWrapper,
}
