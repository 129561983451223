import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: sticky;
  z-index: 100;
  width: 100%;
  position: ${(props) => (props.isRoot ? 'sticky' : 'fixed')};
  top: ${(props) => (props.isRoot ? 0 : '')};
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    position: fixed;
    top: 0;
  }
`

const StyledInnerWrapper = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.xs};
  transition: margin 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: 600;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 0;
    padding: 0 ${(props) => props.theme.spacing.xs};
    padding-left: 1.15rem;
    height: 73px;
  }
`

const StyledItem = styled.div``

export { StyledWrapper, StyledInnerWrapper, StyledItem }
