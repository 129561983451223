import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { translate as translator } from 'Utilities/utility'

const useTranslate = (toTranslate) => {
  const translations = useSelector((store) => store.content.translations)
  const [toTranslateState, setToTranslateState] = useState(null)
  const [localizedValues, setLocalizedValues] = useState({})

  const ref = useRef(toTranslate)

  useEffect(() => {
    setToTranslateState(ref)
  }, [])

  useEffect(() => {
    const translate = (result) => {
      setLocalizedValues(result)
    }

    const getLocalizedValue = (val) => {
      const result = {}
      Object.keys(val).forEach((key) => {
        result[key] = translator(translations, val[key])
      })
      return result
    }

    if (translations) {
      if (Array.isArray(ref.current)) {
        translate(ref.map(() => getLocalizedValue(ref.current)))
      } else {
        translate(getLocalizedValue(ref.current))
      }
    } else {
      setLocalizedValues({})
    }
  }, [translations, toTranslateState])

  return { localized: localizedValues }
}

export { useTranslate }
