import PropTypes from 'prop-types'
import React from 'react'
import { useComponent } from './extended'
import { StyledLink, StyledLinkWrapper } from './styled'

const LanguageSwitch = ({ callback }) => {
  const { langCaption, handleSetLanguage, isTabletOrMobile, locales } =
    useComponent(callback)

  if (locales?.length === 1) {
    return null
  }

  return (
    <StyledLinkWrapper onClick={handleSetLanguage}>
      <StyledLink isTabletOrMobile={isTabletOrMobile}>{langCaption}</StyledLink>
    </StyledLinkWrapper>
  )
}

LanguageSwitch.displayName = 'LanguageSwitch'

LanguageSwitch.propTypes = {
  callback: PropTypes.func,
}

export default LanguageSwitch
