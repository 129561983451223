import PropTypes from 'prop-types'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getCountrySpesificUrl } from 'Utilities/utility'
import {
  StyledLink,
  StyledLinkLine,
  StyledLinkMobile,
  StyledLinkWrapper,
  StyledLinkWrapperMobile,
} from '../styled'

const Link = ({ content, isMobile, callback, isFrontPage }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const url = getCountrySpesificUrl(content?.url || '') || '/'
  let isActive = pathname.startsWith(url) && !isFrontPage
  if (isFrontPage) {
    isActive = pathname === url
  }

  const handleClick = (e, url) => {
    e.preventDefault()
    if (pathname !== getCountrySpesificUrl(url || '')) {
      history.push(getCountrySpesificUrl(url || ''))
      callback && callback()
    }
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <StyledLinkWrapperMobile>
          <StyledLinkMobile onClick={(e) => handleClick(e, content?.url)}>
            {content?.title}
          </StyledLinkMobile>
          {isActive && <StyledLinkLine />}
        </StyledLinkWrapperMobile>
      ) : (
        <StyledLinkWrapper>
          <StyledLink
            isActive={isActive}
            onClick={(e) => handleClick(e, content?.url)}
            href={getCountrySpesificUrl(content?.url || '')}
          >
            {content?.title}
          </StyledLink>
        </StyledLinkWrapper>
      )}
    </React.Fragment>
  )
}

Link.displayName = 'Link'

Link.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
  callback: PropTypes.func,
  isFrontPage: PropTypes.bool,
}

export default Link
