import {
  fetchContactCardItems,
  fetchLabels,
  fetchNavigationItems,
  fetchPageItems,
  fetchPageSectionItems,
  fetchSocialNetworkItems,
  fetchPageItemsByType,
  fetchCompanyItems,
} from 'ApiCMS'
import { put, select } from 'redux-saga/effects'
import * as actions from 'Store/actions'

export function* fetchLocalizedLabelsSaga() {
  yield put(actions.setTranslationsLoaded(false))
  try {
    const localeCode = yield select((store) => store.config.localeCode)
    const response = yield fetchLabels(localeCode)
    yield put(actions.setTranslations(response))
  } catch (error) {
    //console.log(error)
  }
  yield put(actions.setTranslationsLoaded(true))
}

export function* fetchLocalizedPageContentSaga(action) {
  try {
    yield put(actions.setPageContent(null))
    yield put(actions.setPageConfigs(null))
    yield put(actions.setPageContentLoaded(false))
    const localeCode = yield select((store) => store.config.localeCode)
    const pageContentResultArray = yield fetchPageItems(localeCode, action.url)
    pageContentResultArray.length !== undefined
      ? yield put(actions.setPageContent(pageContentResultArray[0]))
      : yield put(actions.setPageContent(null))
    yield put(actions.setPageContentLoaded(true))
  } catch (error) {
    yield put(actions.setPageContentLoaded(true))
  }
}

export function* fetchPageSectionItemsSaga(action) {
  let response = null
  try {
    const localeCode = yield select((store) => store.config.localeCode)
    response = yield fetchPageSectionItems(localeCode, action.sectionsList)
  } catch (error) {
    //console.log(error)
  }
  action.callback && action.callback(response)
}

export function* fetchContactCardItemsSaga(action) {
  let response = null
  try {
    const localeCode = yield select((store) => store.config.localeCode)
    response = yield fetchContactCardItems(localeCode, 'Contact cards')
  } catch (error) {
    //console.log(error)
  }
  action.callback && action.callback(response)
}

export function* fetchSocialNetworkItemsSaga(action) {
  let response = null
  try {
    const localeCode = yield select((store) => store.config.localeCode)
    response = yield fetchSocialNetworkItems(localeCode, 'Social networks')
  } catch (error) {
    //console.log(error)
  }
  action.callback && action.callback(response)
}

export function* fetchNavigationItemsSaga(action) {
  let response = null
  try {
    const localeCode = yield select((store) => store.config.localeCode)
    response = yield fetchNavigationItems(localeCode, action.name)
  } catch (error) {
    //console.log(error)
  }
  action.callback && action.callback(response)
}

export function* fetchCompanyItemsSaga(action) {
  let response = null
  const localeCode = yield select((store) => store.config.localeCode)
  try {
    response = yield fetchCompanyItems(localeCode, action.name)
  } catch (error) {
    //console.log(error)
  }
  action.callback && action.callback(response)
}

export function* fetchPageByTypeItemsSaga(action) {
  let response = null
  try {
    const localeCode = yield select((store) => store.config.localeCode)
    response = yield fetchPageItemsByType(
      action.pageType,
      localeCode,
      action.limit || 3,
      action.skip || 0,
      action.tags || '',
    )
  } catch (error) {
    //console.log(error)
  }
  action.callback && action.callback(response)
}
