import React, { useEffect } from 'react'
import SvgInline from 'Components/SvgInline'
import styles from './parallax.module.scss'

const ParallaxCamp = () => {
  useEffect(() => {
    const scrollEl = document.documentElement
    const root = document.documentElement

    let scrollPos

    // update css property on scroll
    function animation() {
      // check the scroll position has changed
      if (scrollPos !== scrollEl.scrollTop) {
        // reset the seen scroll position
        scrollPos = scrollEl.scrollTop
        // update css property --scrollPos with scroll position in pixels
        root.style.setProperty('--scrollPos', scrollPos + 'px')
      }

      // call animation again on next animation frame
      window.requestAnimationFrame(animation)
    }

    // start animation on next animation frame
    window.requestAnimationFrame(animation)
  }, [])
  return (
    <>
      <div
        className={styles.landscape}
        role="img"
        aria-label="accessibility description here"
      >
        <div className={styles.landscape__layer} style={{ '--offset': 0.98 }}>
          <div className={styles.landscape__image}>
            <SvgInline url={'/camp/landscapeImage1.svg'} />
          </div>
        </div>
        <div className={styles.landscape__layer} style={{ '--offset': 0.6 }}>
          <div className={styles.landscape__image}>
            <SvgInline url={'/camp/landscapeImage2.svg'} />
          </div>
        </div>

        <div className={styles.landscape__layer} style={{ '--offset': 0.5 }}>
          <div className={styles.landscape__image}>
            <SvgInline url={'/camp/landscapeImage3.svg'} />
          </div>
        </div>

        <div className={styles.landscape__layer} style={{ '--offset': 0.4 }}>
          <div className={styles.landscape__image}>
            <SvgInline url={'/camp/landscapeImage4.svg'} />
          </div>
        </div>

        <div className={styles.landscape__layer} style={{ '--offset': 0.3 }}>
          <div className={styles.landscape__image}>
            <SvgInline url={'/camp/landscapeImage5.svg'} />
          </div>
        </div>

        <div className={styles.landscape__layer} style={{ '--offset': 0 }}>
          <div className={styles.landscape__image}>
            <SvgInline url={'/camp/landscapeImage6.svg'} />
          </div>
        </div>
      </div>
    </>
  )
}

ParallaxCamp.displayName = 'ParallaxCamp'

export default ParallaxCamp
