import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const RemoveTrailingSlash = ({ location }) => {
  const { pathname } = location
  if (pathname.substr(-1) === '/' && pathname !== '/') {
    return <Redirect to={pathname.substr(0, pathname.length - 1)} />
  } else {
    return null
  }
}

const Routes = () => {
  const content = React.lazy(() => import('Views/shared/ContentLoader'))
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <React.Fragment>
          <Route pattern="/" render={RemoveTrailingSlash} />
          <Route path={'/'} component={content} />
        </React.Fragment>
        <Redirect to="/" />
      </Switch>
    </Suspense>
  )
}

export default Routes
