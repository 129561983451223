import { takeLatest, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '../actions/actionTypes'
import { appInitSaga } from './config'
import {
  fetchContactCardItemsSaga,
  fetchLocalizedLabelsSaga,
  fetchLocalizedPageContentSaga,
  fetchNavigationItemsSaga,
  fetchPageSectionItemsSaga,
  fetchSocialNetworkItemsSaga,
  fetchPageByTypeItemsSaga,
  fetchCompanyItemsSaga,
} from './content'

export function* watchContent() {
  yield takeLatest(actionTypes.FETCH_TRANSLATIONS, fetchLocalizedLabelsSaga)
  yield takeLatest(
    actionTypes.FETCH_PAGE_SECTION_ITEMS,
    fetchPageSectionItemsSaga,
  )
  yield takeLatest(
    actionTypes.FETCH_SOCIAL_NETWORKS_ITEMS,
    fetchSocialNetworkItemsSaga,
  )
  yield takeEvery(
    actionTypes.FETCH_CONTACT_CARDS_ITEMS,
    fetchContactCardItemsSaga,
  )
  yield takeLatest(
    actionTypes.FETCH_PAGE_CONTENT,
    fetchLocalizedPageContentSaga,
  )
  yield takeEvery(actionTypes.FETCH_NAVIGATION_ITEMS, fetchNavigationItemsSaga)
  yield takeEvery(actionTypes.FETCH_COMPANY_ITEMS, fetchCompanyItemsSaga)

  yield takeEvery(actionTypes.FETCH_PAGES_BY_TYPE, fetchPageByTypeItemsSaga)
}

export function* watchConfig() {
  yield takeLatest(actionTypes.APP_INIT, appInitSaga)
}
