import styled from 'styled-components'
import { PAGE_SECTIONS } from 'Utilities/enums/common'

const StyledWrapper = styled.section`
  position: relative;
  background-color: ${(props) => props.bgColor ?? ''};
  background-image: ${(props) => `url(${props.bgImage})}`};
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgLocation?.toLowerCase()} bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 0;

  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    background: ${(props) => props.layout === PAGE_SECTIONS.SOCIAL && 'none'};
    padding: 25px 0;
  }
`

const StyledSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 25px;
  ${(props) =>
    !props.fullWidth &&
    `
      max-width: 1160px;
  `}
  ${(props) =>
    props.withScroll &&
    `
      margin-top: 88px;
  `}

  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    ${(props) =>
      props.withScroll &&
      `
      margin-top: 25px;
  `}
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    ${(props) =>
      props.withScroll &&
      `
      margin-top: 0;
  `}
  }

  background-color: ${(props) => props.bgColor ?? ''};
  color: ${(props) => `${props.bodyColor || props.fgColor || ''} !important`};
  a {
    color: ${(props) => `${props.bodyColor || props.fgColor || ''} !important`};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: ${(props) => `${props.bodyColor || props.fgColor || ''} !important`};
    color: ${(props) => `${props.bodyColor ? 'initial !important' : ''}`};
  }
`

const StyledSectionBlockDivder = styled.div`
  width: 100%;
  height: 25px;
`

const StyledCenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledCenteredInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  max-width: 864px;
  h1,
  h2,
  h3 {
    font-size: ${(props) => props.theme.fontSize.heading.h3};
    line-height: 50px;
    margin-top: 0;
    font-weight: 300;
    letter-spacing: -0.03em;

    @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: ${(props) => props.theme.fontSize.headingMobile.h3};
      line-height: 32px;
    }

    @media all and (min-width: ${(props) =>
        props.theme.breakpoints.m} and (min-width: ${(props) =>
        props.theme.breakpoints.xl})) {
      font-size: ${(props) => props.theme.fontSize.headingTablet.h3};
    }
  }
  p {
    font-size: ${(props) => props.theme.fontSize.body.xl};

    @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fontSize.bodyMobile.p};
    }
  }
  a {
    color: ${(props) => (props.fgColor ? props.fgColor : '')};
    font-size: ${(props) => props.theme.fontSize.bodyMobile.p};
  }
`

const StyledVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    margin: 0;
  }
`

const StyledVideoWrapperInner = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 25px;

  & > div {
    width: 100%;
    max-width: 800px;
    height: 600px;
    @media all and (min-width: ${(props) => props.theme.breakpoints.xl}) {
      width: 720px;
      height: 480px;
    }
    @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
      width: 540;
      height: 480px;
    }
  }
`

const StyledLeftWrapper = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing.l} 0;
  position: relative;
  width: 100%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.l}) {
    flex-direction: row;
    margin: ${(props) => props.theme.spacing.s} 0;
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
    margin-top: ${(props) => props.theme.spacing.xs2};
    margin-bottom: 0;
    p {
      margin-left: 0;
    }
    h1 {
      margin-top: ${(props) => props.theme.spacing.s};
    }
  }
`

const StyledRightWrapper = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing.l} 0;
  position: relative;
  width: 100%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.l}) {
    flex-direction: row;
    margin: ${(props) => props.theme.spacing.s} 0;
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column-reverse;
    margin-bottom: 0;
    margin-top: ${(props) => props.theme.spacing.xs2};
    p {
      margin-left: 0;
    }
    h1 {
      margin-top: ${(props) => props.theme.spacing.s};
    }
  }
`

const StyledImage = styled.img`
  height: auto;
  width: 100%;
  width: 420px;
  max-width: 420px;
  max-height: 420px;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 320px;
    max-width: 320px;
    max-height: 320px;
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.l}) {
    max-width: 100%;
    width: 320px;
    max-height: 320px;
  }

  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`

const StyledPattern = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.location === 'Left' ? '-64px' : '')};
  right: ${(props) => (props.location === 'Right' ? '-64px' : '')};
  height: 100%;
  width: inherit;
  background-image: ${(props) => `url(${props.image})}`};
  background-position: ${(props) =>
    props.location === 'Right' ? 'right 0 top' : ''};
  flex: 0 0 50%;
  align-items: center;
  background-repeat: no-repeat;
  width: 50%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    display: none;
  }
`

const StyledReveal = styled.div`
  display: flex;
  width: 100%;
  & > .react-reveal {
    width: 100%;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTextInnerWrapper = styled.div`
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 0 24px;
    a {
      font-size: ${(props) => props.theme.fontSize.body.xl};
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 0;
    }
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 0;
  }
`

const StyledSocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${(props) => props.theme.spacing.l} 0;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 24px 0;
    h1 {
      margin-bottom: 16px;
    }
  }
`

const StyledSocialInnerWrapper = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
      flex-direction: column;
      width: 100%;
    }
  }
`

const StyledCompaniesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${(props) => props.theme.spacing.s};
  padding-bottom: ${(props) => props.theme.spacing.l};
  background-color: ${(props) => props.theme.colors.grays[0]};
  width: 100%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    word-break: break-all;
    & > h3 {
      margin: 25px;
    }
  }
`

const StyledCompaniesInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;
    justify-content: center;
  }
`

const StyledCompany = styled.img`
  max-height: 64px;
  margin: 0 24px;
  opacity: 0.7;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'default')};
  @media all and (max-width: ${(props) => props.theme.breakpoints.l}) {
    flex-direction: column;
    max-height: 48px;
    margin: 8px 24px;
  }
`

const StyledContactCardsWrapper = styled.div`
  flex: 0 0 50%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    flex: auto;
  }
`

const StyledSocialIconsWrapper = styled.div`
  flex: 0 0 50%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    flex: auto;
  }
`

const StyledHeader = styled.h2`
  &&& {
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.heading.l};
    @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fontSize.heading.s};
      margin: ${(props) => props.theme.spacing.s};
      margin-top: ${(props) => props.theme.spacing.xs};
      margin-bottom: ${(props) => props.theme.spacing.s};
    }
  }
`

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &&& {
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      cursor: default;
    }
  }
`

const StyledTitle = styled.h2`
  font-weight: 300;
  letter-spacing: -0.03em;
  font-size: ${(props) => props.theme.fontSize.heading.h2};
  text-align: ${(props) => (props.isCentered ? 'center' : '')};
  line-height: 1;
  color: ${(props) => props.theme.colors.primaryBlack};
  margin-top: 0;
  margin-bottom: 50px;

  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${(props) => props.theme.fontSize.headingMobile.h2};
    margin-bottom: 25px;
  }

  @media all and (min-width: ${(props) =>
      props.theme.breakpoints.m} and (min-width: ${(props) =>
      props.theme.breakpoints.xl})) {
    font-size: ${(props) => props.theme.fontSize.headingTablet.h2};
  }
`

const StyledTitleSmall = styled.h3`
  font-weight: 300;
  letter-spacing: -0.03em;
  font-size: ${(props) => props.theme.fontSize.heading.h3};
  text-align: ${(props) => (props.isCentered ? 'center' : '')};
  line-height: 1;
  color: ${(props) => props.theme.colors.primaryBlack};
  margin-top: 0;
  margin-bottom: 50px;
  @media all and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${(props) => props.theme.fontSize.heading.m};
  }
`

const StyledDate = styled.div`
  font-size: ${(props) => props.theme.fontSize.body.m};
  margin-top: ${(props) => props.theme.spacing.s};
  @media all and (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin: 0;
  }

  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fontSize.body.s};
    font-weight: 400;
    margin-top: ${(props) => props.theme.spacing.xs2};
    margin-bottom: 0;
  }
`

const StyledDescription = styled.div`
  font-size: ${(props) => props.theme.fontSize.body.s};
`

const StyledInfiniteWrapper = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing.l} 0;
  position: relative;
  width: 100%;
  @media all and (max-width: ${(props) => props.theme.breakpoints.l}) {
    flex-direction: row;
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
    margin: 0;
    p {
      margin-left: 0;
    }
  }
`

const StyledInfiniteItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing.l};
  p {
    margin-left: 0;
    margin-top: 0;
  }
  & > div:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-top: ${(props) => props.theme.spacing.s};
    & > div:hover {
      background-color: ${(props) => props.theme.colors.white};
      cursor: default;
    }
  }
`

const StyledInfiniteTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.spacing.l};
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 0 24px;
  }

  @media all and (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 0;
  }
`

const StyledAuthor = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  letter-spacing: -0.03em;
  margin: ${(props) => props.theme.spacing.xs} 0;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 0;
  }
`

const StyledAvatar = styled.img`
  border-radius: 50%;
  max-width: 64px;
  margin-right: ${(props) => props.theme.spacing.xs};
`

const StyledSeparator = styled.div`
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    height: 2px;
    width: ${(props) => props.theme.spacing.m};
    background-color: ${(props) => props.theme.colors.green[0]};
    margin-top: ${(props) => props.theme.spacing.xs2};
    margin-bottom: ${(props) => props.theme.spacing.l};
  }
`

const StyledTotal = styled.div`
  font-size: ${(props) => props.theme.fontSize.heading.m};
  margin: ${(props) => props.theme.spacing.xs2} 0;
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fontSize.heading.s};
    margin: ${(props) => props.theme.spacing.xs2} 24px;
  }
`

const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${(props) => props.theme.fontSize.heading.m};
  span {
    margin-right: ${(props) => props.theme.spacing.xs};
  }
  a {
    font-size: ${(props) => props.theme.fontSize.heading.s};
    text-decoration: underline;
  }
  @media all and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fontSize.heading.s};
    margin-bottom: ${(props) => props.theme.spacing.m};
    margin: 0 24px;
    margin-bottom: ${(props) => props.theme.spacing.m};
    &&& {
      overflow-wrap: normal;
      word-break: normal;
    }
  }
`

const StyledTag = styled.div`
  margin-right: ${(props) => props.theme.spacing.xs};
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.theme.colors.green[0]};
`

const StiledLoadMore = styled.a`
  padding: ${(props) => props.theme.spacingLayout.xs}
    ${(props) => props.theme.spacingLayout.s};
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.green[0]};
  color: ${(props) => props.theme.colors.green[0]};
  &:hover {
    background-color: ${(props) => props.theme.colors.grays[0]};
  }
`

export {
  StyledWrapper,
  StyledSectionWrapper,
  StyledSectionBlockDivder,
  StyledCenteredWrapper,
  StyledCenteredInnerWrapper,
  StyledLeftWrapper,
  StyledReveal,
  StyledTextWrapper,
  StyledRightWrapper,
  StyledTextInnerWrapper,
  StyledImage,
  StyledSocialWrapper,
  StyledContactCardsWrapper,
  StyledSocialIconsWrapper,
  StyledSocialInnerWrapper,
  StyledTitle,
  StyledPattern,
  StyledVideoWrapper,
  StyledVideoWrapperInner,
  StyledCompaniesWrapper,
  StyledCompaniesInnerWrapper,
  StyledCompany,
  StyledInfiniteItemsWrapper,
  StyledInfiniteTextWrapper,
  StyledDescription,
  StyledDate,
  StyledAuthor,
  StyledAvatar,
  StyledSeparator,
  StyledInfiniteWrapper,
  StyledHeader,
  StyledHeaderWrapper,
  StyledTotal,
  StyledTags,
  StyledTag,
  StiledLoadMore,
  StyledTitleSmall,
}
