import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`


html {
  font-family: 'noto-sans', sans-serif;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

body {
  color: ${(props) => props.theme.colors.primaryBlack};
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

ol, ul {
  list-style: none;
  padding: 0;
}

li {
  p {
   margin-left: 36px;
  }
  list-style: none;
  background-image: url('/rectangle-green.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 12px;
}

a {
  color: ${(props) => props.theme.colors.primaryBlack};
}
`
export default GlobalStyle
