import { createClient } from 'contentful'
import * as contentTypes from './contentTypes'

const contentfulClient = () =>
  createClient({
    space: process.env.REACT_APP_CMS_SPACE,
    accessToken: process.env.REACT_APP_CMS_ACCESS_TOKEN,
    environment: process.env.REACT_APP_CMS_ENVIRONMENT,
    max_include_resolution_depth: 5,
  })

const fetchEntries = (locale, filter, limit = 1000) =>
  contentfulClient().getEntries({
    locale,
    limit,
    include: 3,
    order: 'sys.createdAt',
    ...filter,
  })

const fetchEntriesByContentType = (contentType, locale) =>
  fetchEntries(locale, { content_type: contentType })

const fetchPageEntriesByContentTypeWithLimit = (
  pageType,
  locale,
  limit,
  skip,
  tags,
) => {
  let query = {
    content_type: contentTypes.PAGE,
    limit: limit || 10,
    skip: skip || 0,
    'fields.pageType': pageType,
    'fields.publishingDate[lte]': new Date().toISOString(),
    order: '-fields.publishingDate',
  }
  if (tags) {
    query['metadata.tags.sys.id[in]'] = tags
  }
  return fetchEntries(locale, query)
}

const fetchEntryByContentTypeAndName = (contentType, locale, name) =>
  fetchEntries(locale, { content_type: contentType, 'fields.title': name })

const fetchLabelEntries = (locale) =>
  fetchEntriesByContentType(contentTypes.LABEL, locale)

const fetchEntriesByContentTypeAndSelectAndUrl = (
  contentType,
  locale,
  fields,
  url,
) =>
  fetchEntries(locale, {
    content_type: contentType,
    select: fields,
    'fields.url': url,
  })

const fetchPageWithFields = (locale, fields, url) =>
  fetchEntriesByContentTypeAndSelectAndUrl(
    contentTypes.PAGE,
    locale,
    fields,
    url,
  )

const fetchEntriesByContentTypeAndTitle = (contentType, locale, title) =>
  fetchEntries(locale, {
    content_type: contentType,
    'fields.title': title,
  })

const fetchConfigsEntries = (title) =>
  fetchEntriesByContentTypeAndTitle(contentTypes.CONFIGS, 'en-US', title)

const fetchPageEntries = (locale, url) =>
  fetchEntries(locale, {
    content_type: contentTypes.PAGE,
    'fields.url': url,
    'fields.publishingDate[lte]': new Date().toISOString(),
  })

const fetchPageSectionEntries = (locale, name) =>
  fetchEntryByContentTypeAndName(contentTypes.PAGE_SECTION, locale, name)

const fetchPageSectionListEntries = (locale, name) =>
  fetchEntryByContentTypeAndName(contentTypes.PAGE_SECTION_LIST, locale, name)

const fetchContactCardsListEntries = (locale, name) =>
  fetchEntryByContentTypeAndName(contentTypes.CONTACT_CARDS_LIST, locale, name)

const fetchSocialNetworksListEntries = (locale, name) =>
  fetchEntryByContentTypeAndName(
    contentTypes.SOCIAL_NETWORKS_LIST,
    locale,
    name,
  )

const fetchNavigationEntries = (locale, name) =>
  fetchEntryByContentTypeAndName(contentTypes.NAVIGATION, locale, name)

const fetchCompanyListEntries = (locale, name) =>
  fetchEntryByContentTypeAndName(contentTypes.COMPANY, locale, name)

export {
  fetchLabelEntries,
  fetchPageEntries,
  fetchPageSectionEntries,
  fetchPageSectionListEntries,
  fetchSocialNetworksListEntries,
  fetchContactCardsListEntries,
  fetchNavigationEntries,
  fetchPageWithFields,
  fetchPageEntriesByContentTypeWithLimit,
  fetchCompanyListEntries,
  fetchConfigsEntries,
}

export default null
