import * as actionTypes from './actionTypes'

export const setPageReady = (pageReady) => {
  return {
    type: actionTypes.SET_PAGE_READY,
    pageReady: pageReady,
  }
}

export const fetchLocalizedPageContent = (url) => {
  return {
    type: actionTypes.FETCH_PAGE_CONTENT,
    url: url,
  }
}

export const setPageContent = (pageContent) => {
  return {
    type: actionTypes.SET_PAGE_CONTENT,
    pageContent: pageContent,
  }
}

export const setPageContentLoaded = (pageContentLoaded) => {
  return {
    type: actionTypes.SET_PAGE_CONTENT_LOADED,
    pageContentLoaded: pageContentLoaded,
  }
}

export const setPageConfigs = (pageConfigs) => {
  return {
    type: actionTypes.SET_PAGE_CONFIGS,
    pageConfigs: pageConfigs,
  }
}

export const setTranslations = (translations) => {
  return {
    type: actionTypes.SET_TRANSLATIONS,
    translations: translations,
  }
}

export const setTranslationsLoaded = (translationsLoaded) => {
  return {
    type: actionTypes.SET_TRANSLATIONS_LOADED,
    translationsLoaded: translationsLoaded,
  }
}

export const fetchLocalizedLabels = (url) => {
  return {
    type: actionTypes.FETCH_TRANSLATIONS,
    url: url,
  }
}

export const fetchPageSectionItems = (sectionsList, callback) => {
  return {
    type: actionTypes.FETCH_PAGE_SECTION_ITEMS,
    sectionsList: sectionsList,
    callback: callback,
  }
}

export const fetchContactCardItems = (callback) => {
  return {
    type: actionTypes.FETCH_CONTACT_CARDS_ITEMS,
    callback: callback,
  }
}

export const fetchSocialNetworkItems = (callback) => {
  return {
    type: actionTypes.FETCH_SOCIAL_NETWORKS_ITEMS,
    callback: callback,
  }
}

export const fetchNavigationItems = (name, callback) => {
  return {
    type: actionTypes.FETCH_NAVIGATION_ITEMS,
    name: name,
    callback: callback,
  }
}

export const fetchCompanyItems = (name, callback) => {
  return {
    type: actionTypes.FETCH_COMPANY_ITEMS,
    name: name,
    callback: callback,
  }
}

export const fetchPageByTypeItems = (pageType, limit, skip, callback, tags) => {
  return {
    type: actionTypes.FETCH_PAGES_BY_TYPE,
    pageType: pageType,
    limit: limit,
    skip: skip,
    callback: callback,
    tags: tags,
  }
}
