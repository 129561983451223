import { updateObject } from 'Utilities/utility'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  translations: null,
  translationsLoaded: false,
  pageReady: false,
  pageContent: null,
  pageContentLoaded: true,
  pageConfigs: null,
}

const setTranslations = (state, action) => {
  return updateObject(state, { translations: action.translations })
}

const setTranslationsLoaded = (state, action) => {
  return updateObject(state, { translationsLoaded: action.translationsLoaded })
}

const setPageContent = (state, action) => {
  return updateObject(state, { pageContent: action.pageContent })
}

const setPageContentLoaded = (state, action) => {
  return updateObject(state, { pageContentLoaded: action.pageContentLoaded })
}

const setPageReady = (state, action) => {
  return updateObject(state, { pageReady: action.pageReady })
}

const setPageConfigs = (state, action) => {
  return updateObject(state, { pageConfigs: action.pageConfigs })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSLATIONS:
      return setTranslations(state, action)
    case actionTypes.SET_TRANSLATIONS_LOADED:
      return setTranslationsLoaded(state, action)
    case actionTypes.SET_PAGE_READY:
      return setPageReady(state, action)
    case actionTypes.SET_PAGE_CONFIGS:
      return setPageConfigs(state, action)
    case actionTypes.SET_PAGE_CONTENT:
      return setPageContent(state, action)
    case actionTypes.SET_PAGE_CONTENT_LOADED:
      return setPageContentLoaded(state, action)
    default:
      return state
  }
}

export default reducer
