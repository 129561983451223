import React from 'react'
import { bool, node } from 'prop-types'
import { StyledMenu, StyledMenuWrapper } from '../../styled'

const Drawer = ({ open, children, ...props }) => {
  const isHidden = open ? true : false

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <StyledMenuWrapper>{children}</StyledMenuWrapper>
    </StyledMenu>
  )
}

Drawer.propTypes = {
  open: bool.isRequired,
  children: node.isRequired,
}

export default Drawer
