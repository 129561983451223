import React from 'react'
import ContactCard from './Card'
import { useComponent } from './extended'
import { StyledWrapper, StyledNavigation, StyledNavigationItem } from './styled'
import PropTypes from 'prop-types'
import { getCountrySpesificUrl } from 'Utilities/utility'
import { isRoot } from '../../utilities/utility'

const ContactCards = ({ withNavigation }) => {
  const { contactCardsData, navigationData, localized } = useComponent()
  const isEven = contactCardsData?.length + ((withNavigation ? 1 : 0) % 2) === 0
  return (
    <StyledWrapper>
      <>
        {contactCardsData?.map((card) => (
          <ContactCard key={card.key} content={card} />
        ))}
        {withNavigation && navigationData && (
          <StyledNavigation>
            {navigationData?.map((navItem) => (
              <StyledNavigationItem
                href={getCountrySpesificUrl(navItem.url)}
                key={navItem.title}
              >
                {isRoot(navItem.url) ? localized.frontPageTitle : navItem.title}
              </StyledNavigationItem>
            ))}
          </StyledNavigation>
        )}
        {!isEven && <div />}
      </>
    </StyledWrapper>
  )
}

ContactCards.displayName = 'ContactCards'

ContactCards.propTypes = {
  withNavigation: PropTypes.any,
}

export default ContactCards
